import * as React from 'react';
import Panel from '../panel/Panel';
import { Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import BackupTab from './BackupTab';
import ProfileSettingsTab from './ProfileSettingsTab';
import Can from '../../components/Can';
import { AbilityContext } from '../../components/Can';

export default observer(() => {
    const { t } = useTranslation();
    const [tab, setTab] = React.useState(0);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const tabMenu = [
        <Tab key="profile" label={t("profile")} />
    ];
    const ability = React.useContext(AbilityContext);
    if (ability.can("manage", "Backup")) {
        tabMenu.push(<Tab key="backup" label={t("backup")} />);
    }

    return (
        <Panel title={t("settings")}>
            <Tabs value={tab} onChange={handleChange}>
                {tabMenu}
            </Tabs>
            <ProfileSettingsTab index={0} tab={tab} />
            <Can do="manage" on='Settings'>
                <BackupTab index={1} tab={tab} />
            </Can>
        </Panel >
    );
});