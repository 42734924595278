import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability';
import { Role } from '../domain/User';

type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';
type Subjects = 'CounterRecord' | 'User' | 'UserRole' | 'Users' | 'Settings' | 'Backup' | 'all' | 'Activity' | 'Knowledge';

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export default function defineRulesFor(roles: Role[]) {
    const { can, cannot, rules } = new AbilityBuilder(AppAbility);

    roles.forEach(role => {
        if (role.name === 'admin') {
            can('manage', 'all');
        }
        if (role.name === 'moderator') {
            can('manage', 'all');
        }
        if (role.name === 'user') {
            can('read', 'User');
            cannot('update', 'UserRole');
            can('manage', 'Settings');
        }
    })

    return rules;
}

export function buildAbilityFor(roles: Role[]): AppAbility {
    return new AppAbility(defineRulesFor(roles));
}
