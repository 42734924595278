import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { Button, Grid, Table, TableBody, TableContainer, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { User } from '../../domain/User';
import { useTranslation } from "react-i18next";
import OverallTableRow from './OverallTableRow';
import { Counter } from '../../domain/Counter';
import MonthSwitcher from '../../components/MonthSwitcher';
import moment from 'moment';
import { CounterRecord } from '../../domain/CounterRecord';

export default observer(() => {
    const { t } = useTranslation();
    const { userStore, counterStore, counterRecordStore } = useStores();
    const [counters, setCounters] = React.useState<Counter[]>([]);
    const [date, setDate] = React.useState<Date>(moment().startOf("month").toDate());

    React.useEffect(() => {
        userStore.getAllUsers();
        counterStore.getAllCounters((counters) => {
            setCounters(counters);
        });
    }, []);

    const downloadExcel = () => {
        counterRecordStore.downloadExcel(date);
    }

    const getRecords = () => {
        let records: CounterRecord[] = [];
        counters.forEach(c => records = [...records, ...c.records])
        const curRecords = records.filter(r => moment(r.date).isSame(moment(date)));
        return curRecords;
    }

    return (
        <Panel
            title={t("overall")}
            headerItems={[
                <Button
                    key="download-excel-btn"
                    color="inherit"
                    type="button"
                    onClick={downloadExcel}>
                    {t("download_excel")}
                </Button>
            ]}>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={6} >
                            <MonthSwitcher
                                date={date}
                                onDateUpdate={(date: Date) => {
                                    setDate(moment(date).startOf("month").toDate());
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <Typography variant="h4" component="h4" textAlign="right">
                                {`${getRecords().length}/${counters.length}`}
                            </Typography>
                        </Grid>
                    </Grid>

                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                {userStore.users && userStore.users
                                    .slice()
                                    .sort((a, b) => a.apartment - b.apartment)
                                    .map((user: User, i: number) => (
                                        <OverallTableRow
                                            key={user.id}
                                            user={user}
                                            date={date}
                                            counters={counters.filter(counter => counter.userId === user.id)}
                                        />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Container>
        </Panel >
    );
});