import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';

export interface AlertRecord {
    id: number;
    type: string;
    message: string;
}

export class AlertStore {
    rootStore: RootStore;
    serverStore: ServerStore;
    alerts: AlertRecord[];

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        this.alerts = [];
        makeAutoObservable(this);
    }

    getAlerts = () => {
        this.serverStore.get('/alert', action((data) => {
            this.alerts = data;  
        }));
    }
}