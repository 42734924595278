import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useStores } from '../../stores';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react-lite';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";
import Can from '../../components/Can';
import { useConfirm } from 'material-ui-confirm';
import { Counter } from '../../domain/Counter';
import moment from 'moment';

interface Props {
    userId: number;
    onCounterUpdate: (counter: Counter) => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { counterStore } = useStores();
    const confirm = useConfirm();
    const [counters, setCounters] = React.useState<Counter[]>([]);
    const userId = props.userId;
    
    React.useEffect(() => {
        if (counterStore.counters[userId]) {
            setCounters(counterStore.counters[userId]);
        } else {
            counterStore.getCounters(userId, (counters) => setCounters(counters));
        }
    }, [counterStore.counters[userId]]);

    const deleteCounter = (counter: Counter) => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            counterStore.deleteCounter(counter, () => {
                counterStore.getCounters(userId, (counters) => setCounters(counters));
            });
        });
    }

    return (
        <Paper>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("name")}</TableCell>
                            <TableCell>{t("number")}</TableCell>
                            <TableCell>{t("expiry_date")}</TableCell>
                            <TableCell>{t("type")}</TableCell>
                            <Can do="manage" on='CounterRecord'>
                                <TableCell>{t("actions")}</TableCell>
                            </Can>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {counters.map((counter: Counter, i: number) => (
                                <TableRow key={counter.id}>
                                    <TableCell>{counter.name}</TableCell>
                                    <TableCell>{counter.number}</TableCell>
                                    <TableCell>{moment(counter.expiryDate).format("MM.YYYY")}</TableCell>
                                    <TableCell>{t(counter.type)}</TableCell>
                                    <TableCell>
                                        <Can do="manage" on='CounterRecord'>
                                            <IconButton onClick={() => props.onCounterUpdate(counter)}>
                                                <EditIcon color="primary" />
                                            </IconButton>
                                            <IconButton onClick={() => deleteCounter(counter)}>
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </Can>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
});