import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';
import { KnowledgeTopic } from '../domain/KnowledgeTopic';
import { KnowledgeNote } from '../domain/KnowledgeNote';

export class KnowledgeStore {
    rootStore: RootStore;
    serverStore: ServerStore;
    knowledgeTopics: KnowledgeTopic[] = [];
    knowledgeNotes: KnowledgeNote[] = [];

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    getKnowledgeTopics = () => {
        this.serverStore.get('/knowledge/topic', action((data) => {
            this.knowledgeTopics = data;  
        }));
    }

    getKnowledgeNotes = () => {
        this.serverStore.get('/knowledge/note', action((data) => {
            this.knowledgeNotes = data;
        }));
    }

    saveKnowledgeTopic = (topic: any) => {
        this.serverStore.post('/knowledge/topic', topic, () => {
            this.getKnowledgeTopics();
            this.rootStore.notificationsStore.add("new_knowledge_topic_is_saved", "success");
        });
    }

    updateKnowledgeTopic = (id: number, topic: any) => {
        this.serverStore.patch('/knowledge/topic/' + id, topic, () => {
            this.getKnowledgeTopics();
            this.rootStore.notificationsStore.add("knowledge_topic_is_updated", "success");
        });
    }

    deleteKnowledgeTopic = (id: number) => {
        this.serverStore.delete('/knowledge/topic/' + id, () => {
            this.getKnowledgeTopics();
            this.rootStore.notificationsStore.add("knowledge_topic_is_deleted", "success");
        });
    }

    saveKnowledgeNote = (note: any) => {
        this.serverStore.post('/knowledge/note', note, () => {
            this.getKnowledgeTopics();
            this.rootStore.notificationsStore.add("knowledge_note_is_saved", "success");
        });
    }

    updateKnowledgeNote = (id: number, note: any) => {
        this.serverStore.patch('/knowledge/note/' + id, note, () => {
            this.getKnowledgeTopics();
            this.rootStore.notificationsStore.add("knowledge_note_is_updated", "success");
        });
    }

    deleteKnowledgeNote = (id: number) => {
        this.serverStore.delete('/knowledge/note/' + id, () => {
            this.getKnowledgeTopics();
            this.rootStore.notificationsStore.add("knowledge_note_is_deleted", "success");
        });
    }
}