import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translations: {
                    "cancel": "Cancel",
                    "submit": "Submit",
                    "home": "Home",
                    "users": "Users",
                    "settings": "Settings",
                    "logout": "Logout",
                    "username": "Username",
                    "email": "Email",
                    "roles": "Roles",
                    "actions": "Action",
                    "password": "Password",
                    "update_user": "Update User",
                    "new_user": "New User",
                    "add_new_user": "Add new User",
                    "name": "Name",
                    "surname": "Surname",
                    "birth_date": "Birth Date",
                    "address": "Address",
                    "phone_number": "Phone Number",
                    "date": "Date",
                    "placements": "Placements",
                    "notes": "Notes",
                    "you_are_admin": "You are an administrator",
                    "update_user_info": "Update User Info",
                    "sign_in": "Sign in",
                    "none": "None",
                    "number": "Number",
                    "gender": "Gender",
                    "male": "Male",
                    "female": "Female",
                    "yes": "Yes",
                    "no": "No",
                    "amount": "Amount",
                    "from": "From",
                    "to": "To",
                    "are_you_sure": "Are you sure?",
                    "this_will_permanently_delete": "This will permanently delete",
                    "workdays": "Workdays",
                    "weekends": "Weekends",
                    "workday": "Workday",
                    "weekend": "Weekend",
                    "year": "Year",
                    "hello": "Hello",
                    "save_all": "Save all",
                    "backup": "Backup",
                    "download": "Download",
                    "upload": "Upload",
                    "change_password": "Change Password",
                    "repeat_password": "Repeat Password",
                    "new_user_created": "New User {{name}} Created",
                    "new_user_creation_failed": "New User Creation Failed",
                    "passwords_are_different": "Passwords are different!",
                    "password_changed_successfully": "Password changed successfully",
                    "failed_to_change_password": "Failed to change password",
                    "year_is_not_specified": "Year is not specified",
                    "user_is_deleted": "User {{name}} is deleted",
                    "list": "List",
                    "user_is_updated": "User {{name}} is updated",
                    "Session is over": "Session is over",
                    "age": "Age",
                    "user_logged_in": "User {{name}} is logged in",
                    "average": "Average",
                    "overall": "Overall",
                    "all": "All",
                    "count": "Count",
                    "day_of_week": "Day of a week",
                    "graph": "Graph",
                    "table": "Table",
                    "description": "Description",
                    "total": "Total",
                    "add_entry": "Add Entry",
                    "save": "Save",
                    "other": "Other",
                    "type": "Type",
                    "delete": "Delete",
                    "today": "Today",
                    "time": "Time",
                    "event": "Events",
                    "all_day": "All day",
                    "week": "Week",
                    "work_week": "Work week",
                    "day": "Day",
                    "month": "Month",
                    "previous": "Previous",
                    "next": "Next",
                    "yesterday": "Yesterday",
                    "tomorrow": "Tomorrow",
                    "started_at": "Started at",
                    "field_is_required": "Field {{name}} is required",
                    "password_is_too_short": "Password is too short - should be {{number}} chars minimum",
                    "add_at_least_one_role": "Add at least one role",
                    "profile": "Profile",
                    "activity": "Activity",
                    "records": "Records",
                    "user": "User",
                    "prev.": "Prev.",
                    "next.": "Next",
                    "notifications": "Notifications",
                    "notification": "Notification",
                    "month_start": "Month Start",
                    "month_end": "Month End",
                    "summary": "Summary",
                    "history": "History",
                    "your_records_are_definitely_wrong": "Your recrods are definitely wrong. Please fix",
                    "your_records_looks_suspicious": "There may be an error in your entries. If not, click 'Yes'",
                }
            },
            ru: {
                translations: {
                    "cancel": "Отменить",
                    "submit": "Подтвердить",
                    "home": "Главная",
                    "users": "Пользователи",
                    "settings": "Настройки",
                    "logout": "Выход",
                    "username": "Имя пользователя",
                    "email": "Э-почта",
                    "roles": "Роли",
                    "actions": "Действие",
                    "password": "Пароль",
                    "update_user": "Обновить пользователя",
                    "new_user": "Новый пользователь",
                    "add_new_user": "Добавить нового пользователя",
                    "name": "Имя",
                    "surname": "Фамилия",
                    "birth_date": "День рождения",
                    "address": "Адрес",
                    "phone_number": "Номер телефона",
                    "date": "Дата",
                    "notes": "Заметки",
                    "you_are_admin": "Вы администратор",
                    "update_user_info": "Обновить данные пользователя",
                    "sign_in": "Вход",
                    "none": "Нет",
                    "groups": "Группы",
                    "number": "Номер",
                    "gender": "Пол",
                    "male": "Мужчина",
                    "female": "Женщина",
                    "yes": "Да",
                    "no": "Нет",
                    "amount": "Кол-во",
                    "from": "С",
                    "to": "По",
                    "are_you_sure": "Вы уверены?",
                    "this_will_permanently_delete": "Это навсегда удалит",
                    "workdays": "Рабочие дни",
                    "weekends": "Выходные",
                    "workday": "Рабочий день",
                    "weekend": "Выходной",
                    "year": "Год",
                    "hello": "Добрый день",
                    "save_all": "Сохранить всё",
                    "backup": "Бэкап",
                    "download": "Скачать",
                    "upload": "Загрузить",
                    "change_password": "Поменять пароль",
                    "repeat_password": "Повторите пароль",
                    "new_user_created": "Новый пользователь {{name}} создан",
                    "new_user_creation_failed": "Не получилось создать нового пользователя ",
                    "passwords_are_different": "Пароли разные!",
                    "password_changed_successfully": "Пароль поменян успешно",
                    "failed_to_change_password": "Не получилось поменять пароль",
                    "year_is_not_specified": "Год не указан",
                    "user_is_deleted": "Пользователь {{name}} удалён",
                    "list": "Список",
                    "user_is_updated": "Пользователь {{name}} обновлён",
                    "Session is over": "Сессия завершилась",
                    "age": "Возраст",
                    "average": "Среднее",
                    "overall": "Всего",
                    "active": "Активный",
                    "all": "Все",
                    "count": "Кол-во",
                    "day_of_week": "День недели",
                    "graph": "График",
                    "table": "Таблица",
                    "description": "Описание",
                    "add_entry": "Добавить запись",
                    "save": "Сохранить",
                    "other": "Другое",
                    "delete": "Удалить",
                    "today": "Сегодня",
                    "time": "Время",
                    "event": "События",
                    "all_day": "Весь день",
                    "week": "Неделя",
                    "work_week": "Рабочая неделя",
                    "day": "День",
                    "month": "Месяц",
                    "previous": "Предыдущий",
                    "next": "Следующий",
                    "yesterday": "Вчера",
                    "tomorrow": "Завтра",
                    "field_is_required": "Поле '{{name}}' обязательно",
                    "password_is_too_short": "Пароль слишком короткий, должно быть как минимум {{number}} симвлов",
                    "add_at_least_one_role": "Нужна хотя бы одна роль",
                    "profile": "Профиль",
                    "activity": "Активность",
                    "records": "Записи",
                    "user": "Пользователь",
                    "prev.": "Пред.",
                    "next.": "След.",
                    "month_start": "Начало месяца",
                    "month_end": "Конец месяца",
                    "summary": "Сводка",
                    "difference": "Разница",
                    "history": "История",
                    "enter_counter_record": "Внести показания счетчиков",
                    "apartment": "Квартира",
                    "cold": "Холодная",
                    "hot": "Горячая",
                    "new_counter_record": "Новая запись счетчиков",
                    "cold_water": "Холодная вода",
                    "hot_water": "Горячая вода",
                    "counters": "Счетчики",
                    "new_counter": "Новый счетчик",
                    "language": "Язык",
                    "latvian": "Латышский",
                    "russian": "Русский",
                    "expiry_date": "Срок",
                    "type": "Тип",
                    "download_excel": "Скачать Excel",
                    "your_records_are_definitely_wrong": "Ваши записи определенно неверны, пожалуйста исправьте",
                    "your_records_looks_suspicious": "Возможно в ваших записях есть ошибка. Если нет, то нажмите 'Да'",
                }
            },
            lv: {
                translations: {
                    "cancel": "Atcelt",
                    "submit": "Iesniegt",
                    "home": "Mājas",
                    "users": "Lietotāji",
                    "settings": "Iestatījumi",
                    "logout": "Izlogoties",
                    "username": "Lietotājvārds",
                    "email": "E-pasts",
                    "roles": "Lomas",
                    "actions": "Darbības",
                    "password": "Parole",
                    "update_user": "Atjaunināt lietotāju",
                    "new_user": "Jauns lietotājs",
                    "add_new_user": "Pievienot jaunu lietotāju",
                    "name": "Vārds",
                    "surname": "Uzvārds",
                    "birth_date": "Dzimšanas diena",
                    "address": "Adrese",
                    "phone_number": "Telefona numurs",
                    "date": "Datums",
                    "notes": "Piezīmes",
                    "you_are_admin": "Jūs esat administrators",
                    "update_user_info": "Atjauniniet lietotāja informāciju",
                    "sign_in": "Ieeja",
                    "none": "Nē",
                    "groups": "Grupas",
                    "number": "Numurs",
                    "gender": "Dzimums",
                    "male": "Vīrietis",
                    "female": "Sieviete",
                    "yes": "Jā",
                    "no": "Nē",
                    "amount": "Daudzums",
                    "from": "No",
                    "to": "Līdz",
                    "are_you_sure": "Vai tu esi pārliecināts?",
                    "this_will_permanently_delete": "Tas tiks neatgriezeniski izdzēsts",
                    "workdays": "Darba dienas",
                    "weekends": "Brīvdienas",
                    "workday": "Darba diena",
                    "weekend": "Brīvdiena",
                    "year": "Gads",
                    "hello": "Labdien",
                    "save_all": "Saglabāt visu",
                    "backup": "Dublējums",
                    "download": "Lejupielādēt",
                    "upload": "Lejupielādēt",
                    "change_password": "Mainīt paroli",
                    "repeat_password": "Atkārtojiet paroli",
                    "new_user_created": "Izveidots jauns lietotājs {{name}}",
                    "new_user_creation_failed": "Neizdevās izveidot jaunu lietotāju ",
                    "passwords_are_different": "Paroles ir dažādas!",
                    "password_changed_successfully": "Parole veiksmīgi nomainīta",
                    "failed_to_change_password": "Neizdevās nomainīt paroli",
                    "year_is_not_specified": "Gads nav norādīts",
                    "user_is_deleted": "Lietotājs {{name}} ir izdzēsts",
                    "list": "Saraksts",
                    "user_is_updated": "Lietotājs {{name}} ir atjaunināts",
                    "Session is over": "Sesija beigusies",
                    "age": "Vecums",
                    "average": "Vidēji",
                    "overall": "Kopā",
                    "active": "Aktīvs",
                    "all": "Visi",
                    "count": "Daudzums",
                    "day_of_week": "Nedēļas diena",
                    "graph": "Grafiks",
                    "table": "Tabula",
                    "description": "Apraksts",
                    "add_entry": "Pievienojiet piezīmi",
                    "save": "Saglabāt",
                    "other": "Cits",
                    "delete": "Dzēst",
                    "today": "Šodien",
                    "time": "Laiks",
                    "event": "Pasākumi",
                    "all_day": "Visu dienu",
                    "week": "Nedēļa",
                    "work_week": "Darba nedēļa",
                    "day": "Diena",
                    "month": "Mēnesis",
                    "previous": "Iepriekšējais",
                    "next": "Nākamais",
                    "yesterday": "Vakar",
                    "tomorrow": "Rīt",
                    "field_is_required": "Lauks '{{name}}' ir jāaizpilda obligāti",
                    "password_is_too_short": "Parole ir pārāk īsa, tai ir jābūt vismaz {{number}} rakstzīmēm",
                    "add_at_least_one_role": "Ir nepieciešama vismaz viena loma",
                    "profile": "Profils",
                    "activity": "Aktivitāte",
                    "records": "Ieraksti",
                    "user": "Lietotājs",
                    "month_start": "Mēneša sākums",
                    "month_end": "Mēneša beigas",
                    "summary": "Kopsavilkums",
                    "difference": "Atšķirība",
                    "history": "Vēsture",
                    "enter_counter_record": "Ievadiet skaitītāja rādījumus",
                    "apartment": "Dzīvoklis",
                    "cold": "Auksts",
                    "hot": "Karsts",
                    "new_counter_record": "Jauns skaitītāja ieraksts",
                    "counters": "Skaitītāji",
                    "new_counter": "Jauns Skaitītājs",
                    "cold_water": "Auksts ūdens",
                    "hot_water": "Karsts ūdens",
                    "language": "Valoda",
                    "latvian": "Latviešu",
                    "russian": "Krievu",
                    "expiry_date": "Termiņš",
                    "type": "Veids",
                    "download_excel": "Lejupielādēt Excel",
                    "your_records_are_definitely_wrong": "Jūsu ieraksti noteikti nav pareizi, lūdzu, izlabojiet",
                    "your_records_looks_suspicious": "Iespējams, jūsu ierakstos ir kļūda. Ja nē, noklikšķiniet uz 'Jā'",
                }
            }
        },
        fallbackLng: "en",
        debug: false,
        lng: localStorage.i18nextLng || "lv",
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
