import { Divider, IconButton, List, Toolbar } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SchoolIcon from '@mui/icons-material/School';
import { Link } from "react-router-dom";
import Can from '../../components/Can';
import { useTranslation } from "react-i18next";
import { isMobileOnly } from 'react-device-detect';

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }: any) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export default observer(() => {
    const { t } = useTranslation();
    const { panelStore, userStore } = useStores();
    const toggleDrawer = () => {
        panelStore.setCollapsed(!panelStore.sideBarCollapsed);
    };

    const handleLogoutClick = () => {
        userStore.logout();
    }

    const hidden = isMobileOnly ? panelStore.sideBarCollapsed : false;
    return (
        <Drawer variant="permanent" hidden={hidden} open={!panelStore.sideBarCollapsed} >
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
            >
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
                <ListItemButton component={Link} to="/home">
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("home")} />
                </ListItemButton>
                <Can do="manage" on='CounterRecord'>
                <ListItemButton component={Link} to="/overall">
                    <ListItemIcon>
                        <FormatListNumberedIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("overall")} />
                </ListItemButton>
                </Can>
                {/* <Can do="read" on='MyCounterRecords'>
                    <ListItemButton component={Link} to="/records">
                        <ListItemIcon>
                            <FormatListNumberedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("records")} />
                    </ListItemButton>
                </Can>
                <Can do="read" on='Knowledge'>
                    <ListItemButton component={Link} to="/knowledge">
                        <ListItemIcon>
                            <SchoolIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("knowledge")} />
                    </ListItemButton>
                </Can> */}
                <Can do="read" on='Users'>
                    <ListItemButton component={Link} to="/users">
                        <ListItemIcon>
                            <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("users")} />
                    </ListItemButton>
                </Can>
                <Divider sx={{ my: 1 }} />
                <Can do="update" on='Settings'>
                    <ListItemButton component={Link} to="/settings">
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("settings")} />
                    </ListItemButton>
                </Can>
                <Can do="manage" on='Activity'>
                    <ListItemButton component={Link} to="/activity">
                        <ListItemIcon>
                            <PeopleOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("activity")} />
                    </ListItemButton>
                </Can>
                <ListItemButton onClick={handleLogoutClick} >
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("logout")} />
                </ListItemButton>
            </List>
        </Drawer >
    )
})
