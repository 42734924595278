import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';
import { CounterRecord } from '../domain/CounterRecord';

export class CounterRecordStore {
    rootStore: RootStore;
    serverStore: ServerStore;
    records: { [key:string]: CounterRecord[] }

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        this.records = {};
        makeAutoObservable(this);
    }

    createRecord(recordData: any, callback: (result: boolean) => void) {
        this.serverStore.post('/counter-record', recordData, action((data) => {
            this.rootStore.notificationsStore.add('new_counter_record_created', "success");
            callback(true);
        }), (error) => {
            this.rootStore.notificationsStore.add('new_counter_record_failed', "error");
        });
    }

    saveRecords(records: any[], callback?: (result: boolean) => void) {
        this.serverStore.post('/counter-record/batch',
        records,
            action((data) => {
                this.rootStore.notificationsStore.add('counter_record_is_updated', "success");
                callback && callback(true);
            }));
    }

    updateRecord(recordData: any, callback?: (result: boolean) => void) {
        this.serverStore.patch('/counter-record/' + recordData.id,
        recordData,
            action((data) => {
                this.rootStore.notificationsStore.add('counter_record_is_updated', "success");
                callback && callback(true);
            }));
    }

    deleteRecords(records: any[], callback?: (result: boolean) => void) {
        this.serverStore.post('/counter-record/delete-batch',
        records,
            action((data) => {
                this.rootStore.notificationsStore.add('counter_record_is_updated', "success");
                callback && callback(true);
            }));
    }

    deleteRecord(record: CounterRecord, callback?: (result: boolean) => void) {
        this.serverStore.delete('/counter-record/' + record.id,
            action((data) => {
                this.rootStore.notificationsStore.add('counter_record_is_deleted', "success");
                callback && callback(true);
            }));
    }

    getMyRecords(callback?: (records: CounterRecord[]) => void) {
        this.getRecords(this.rootStore.userStore.userData.id, callback);
    }

    getRecords(userId: number, callback?: (records: CounterRecord[]) => void) {
        this.serverStore.get(`/counter-record?userId=${userId}`, action((records) => {
            this.records[userId] = records;
            callback && callback(records);
        }));
    }

    downloadExcel = (date: Date) => {
        this.serverStore.download('/counter-record/excel', { date });
    }
}