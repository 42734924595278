import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import { isMobileOnly } from 'react-device-detect';

interface PublisherPage {
    filter: GridFilterModel;
    sort: GridSortModel;
    page: number;
    current: number;
}

interface GroupReportPage {
    filter: string;
    page: number;
    date: Date;
}

export class PanelStore {
    sideBarCollapsed = isMobileOnly;
    publishersPage = {} as PublisherPage;
    groupReportPage = {
        filter: "All",
        page: 0,
        date: moment().subtract(1, "month").toDate()
    } as GroupReportPage;

    constructor() {
        makeAutoObservable(this);
    }

    setCollapsed(collapsed: boolean) {
        this.sideBarCollapsed = collapsed;
    }

    setPublishersPagePage = (page: number) => {
        this.publishersPage.page = page;
    }

    setPublishersPageFilter = (filter: GridFilterModel) => {
        this.publishersPage.filter = filter;
    }

    setPublishersPageSort = (sort: GridSortModel) => {
        this.publishersPage.sort = sort;
    }

    setPublishersPageCurrent = (id: number) => {
        this.publishersPage.current = id;
    }

    setGroupReportPageFilter = (filter: string) => {
        this.groupReportPage.filter = filter;
    }

    setGroupReportPagePage = (page: number) => {
        this.groupReportPage.page = page;
    }

    setGroupReportPageDate = (date: Date) => {
        this.groupReportPage.date = date;
    }
}