import { action, makeAutoObservable } from 'mobx';
import axios from 'axios';
import { RootStore } from './RootStore';
import download from 'downloadjs';
import "../communication/server";
import { NotificationsStore } from './NotificationStore';

export class ServerStore {
    rootStore: RootStore;
    notificationsStore: NotificationsStore;

    serverRequestLoading = false;
    errors = undefined;

    constructor(rootStore: RootStore, notificationsStore: NotificationsStore) {
        this.rootStore = rootStore;
        this.notificationsStore = notificationsStore;
        makeAutoObservable(this);
    }

    get = (path: string, success: (result: any) => void, error?: (response: any) => void) => {
        this.serverRequestLoading = true;
        axios.get(path, { withCredentials: true })
            .then(({ data }) => success(data))
            .catch(({ response }) => this.onError(response, error))
            .finally(action(() => { this.serverRequestLoading = false; }));
    }

    post = (path: string, body: any, success: (result: any) => void, error?: (response: any) => void) => {
        this.serverRequestLoading = true;
        axios.post(path, body, { withCredentials: true })
            .then(({ data }) => success(data))
            .catch(({ response }) => this.onError(response, error))
            .finally(action(() => { this.serverRequestLoading = false; }));
    }

    put = (path: string, body: any, success: (result: any) => void, error?: (response: any) => void) => {
        this.serverRequestLoading = true;
        axios.put(path, body, { withCredentials: true })
            .then(({ data }) => success(data))
            .catch(({ response }) => this.onError(response, error))
            .finally(action(() => { this.serverRequestLoading = false; }));
    }

    patch = (path: string, body: any, success: (result: any) => void, error?: (response: any) => void) => {
        this.serverRequestLoading = true;
        axios.patch(path, body, { withCredentials: true })
            .then(({ data }) => success(data))
            .catch(({ response }) => this.onError(response, error))
            .finally(action(() => { this.serverRequestLoading = false; }));
    }

    delete = (path: string, success: (result: any) => void, error?: (response: any) => void) => {
        this.serverRequestLoading = true;
        axios.delete(path, { withCredentials: true })
            .then(({ data }) => success(data))
            .catch(({ response }) => this.onError(response, error))
            .finally(action(() => { this.serverRequestLoading = false; }));
    }

    download = (path: string, body?: any) => {
        this.serverRequestLoading = true;
        axios.post(path, body, { withCredentials: true, responseType: 'blob' })
            .then(({ headers, data }) => {
                const content = headers['content-type'];
                const filename = this.getFileName(headers['content-disposition']);
                download(data, filename, content)
            })
            .catch(action(({ response }) => this.onError(response)))
            .finally(action(() => { this.serverRequestLoading = false; }));
    }

    upload = (path: string, file: any, success: (result: any) => void, error?: (response: any) => void) => {
        this.serverRequestLoading = true;
        const formData = new FormData();
        formData.append('file', file);
        axios.post(path, formData, { withCredentials: true, headers: { 'Content-Type': 'multipart/form-data' } })
            .then(({ data }) => success(data))
            .catch(action((data) => error && error(data.response.data)))
            .finally(action(() => this.serverRequestLoading = false));
    }

    onError = (response: any, error?: (error: any) => void) => {
        if (this.isSessionOver(response)) {
            this.rootStore.userStore.reset();
        } else if (response && response.data) {
            if (response.data.statusCode === 403) {
                console.error(`Error: ${response.data.statusCode} ${response.data.message}`)
            } else {
                this.notificationsStore.add(response.data.message, "error");
            }
        }
        error && error(response);
    }

    isSessionOver = (response: any) => {
        if (response) {
            return response.status === 401 && response.data.message === "Session is over";
        } else return false; // is it correct behaviour?
    }

    getFileName = (header: string): string => {
        let filename = "";
        if (header && header.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(header);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        return filename;
    }

}