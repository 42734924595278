import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';

let index = 0;

export interface Notification {
    id: number;
    type: "success" | "error";
    values?: { [key:string]: string };
    message: string;
}

export class NotificationsStore {
    rootStore: RootStore;
    notifications: Notification[];

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.notifications = [];
        makeAutoObservable(this);
    }

    add = (message: string, type: "success" | "error", values?: { [key:string]: string }) => {
        const hasSame = this.notifications.find(notif => notif.message === message);
        if (!hasSame) {
            this.notifications.push({
                id: ++index,
                type,
                message,
                values
            });
        }
    }

    remove = (id: number) => {
        this.notifications = this.notifications.filter(notification => notification.id !== id)
    }

}