import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useStores } from '../../stores';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react-lite';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";
import Can from '../../components/Can';
import { useConfirm } from 'material-ui-confirm';
import { CounterRecord } from '../../domain/CounterRecord';
import moment from 'moment';
import { Counter } from '../../domain/Counter';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import CounterRecordDialog from './CounterRecordDialog';

interface Props {
    userId: number;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { counterRecordStore, counterStore } = useStores();
    const confirm = useConfirm();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [counters, setCounters] = React.useState<Counter[]>([]);
    const [selectedDate, setSelectedDate] = React.useState<Date>();

    const userId = props.userId;
    const counterHeaders = counters.map(counter => counter.name);
    const rows = counters.reduce((rows, counter) => {
        counter.records.forEach(record => {
            const key = moment(record.date).format("MM.YYYY");
            let arr = rows[key];
            if (!arr) {
                arr = [];
            }
            arr.push(record);
            rows[key] = arr;
        });
        return rows;
    }, {} as { [key: string]: CounterRecord[] });

    React.useEffect(() => {
        if (counterStore.counters[userId]) {
            setCounters(counterStore.counters[userId]);
        } else {
            counterStore.getCounters(userId, (counters) => setCounters(counters));
        }
    }, [counterStore.counters[userId]]);

    const updateRecords = (date: string) => {
        setSelectedDate(moment(date, "MM.YYYY").toDate());
        openDialog("counter-record");
    }

    const deleteRecords = (date: string) => {
        confirm({
            title: t("are_you_sure"),
            description: `${t("this_will_permanently_delete")}`,
            confirmationText: t("yes"),
            cancellationText: t("no")
        }).then(() => {
            const records = rows[date];
            counterRecordStore.deleteRecords(records, () => {
                counterStore.getCounters(userId, (counters) => setCounters(counters));
            });
        });
    }

    const keys = Object.keys(rows).slice().sort((a, b) => moment(b, "MM.YYYY").diff(moment(a, "MM.YYYY")));
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t("date")}</TableCell>
                        {counterHeaders.map(name => <TableCell key={name}>{name}</TableCell>)}
                        <Can do="manage" on='CounterRecord'>
                            <TableCell>{t("actions")}</TableCell>
                        </Can>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {keys.map((date, i) => {
                        const records = rows[date];
                        return <TableRow key={date}>
                            <TableCell>{date}</TableCell>
                            {records.map((record, j) => {
                                let diff = '';
                                if (i < keys.length) {
                                    const prevDate = keys[i + 1];
                                    const prevRecords = rows[prevDate];
                                    if (prevRecords && j < prevRecords.length) {
                                        const prevRecord = prevRecords[j];
                                        diff = ` (+${(record.value - prevRecord.value).toFixed(3)})`;
                                    }
                                }
                                return <TableCell key={record.id}><b>{record.value}</b>{diff}</TableCell>;
                            })}
                            <TableCell>
                                <Can do="manage" on='CounterRecord'>
                                    <IconButton onClick={() => updateRecords(date)}>
                                        <EditIcon color="primary" />
                                    </IconButton>
                                    <IconButton onClick={() => deleteRecords(date)}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </Can>
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
            {isOpen("counter-record") && <CounterRecordDialog
                userId={userId}
                date={selectedDate}
                isOpen={isOpen("counter-record")}
                onClose={() => {
                    setSelectedDate(undefined);
                    closeDialog();
                }}
            />}
        </TableContainer>

    );
});