import { useStores } from '../../stores';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Autocomplete } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Role, User } from '../../domain/User';
import { useTranslation } from "react-i18next";
import Can from '../../components/Can';
import * as yup from 'yup';

interface Props {
    isOpen: boolean;
    user?: User;
    onClose: () => void;
}

interface FormValues {
    username: string;
    name: string;
    email: string;
    apartment: number;
    roles: Role[];
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { userStore } = useStores();

    const initialValues = {} as FormValues;
    if (props.user) {
        initialValues.username = props.user.username;
        initialValues.name = props.user.name;
        initialValues.email = props.user.email || '';
        initialValues.roles = props.user.roles;
        initialValues.apartment = props.user.apartment;
    }

    const schema = yup.object().shape({
        username: yup.string().required(t("field_is_required", { name: t("username") })),
        email: yup.string().email(),
        apartment: yup.number().required(t("field_is_required", { name: t("apartment") })),
        roles: yup.array().min(1, t('add_at_least_one_role')).required(),
    });
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues,
        onSubmit: (values) => {
            const userData = { ...values } as any;
            if (props.user) {
                userData.id = props.user.id;
            }

            userStore.updateUser(userData, (result) => {
                props.onClose();
            });
        },
    });

    const { errors, values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>{t("update_user")}</DialogTitle>
                <DialogContent>

                    <TextField
                        autoFocus
                        margin="normal"
                        id="username"
                        label={t("username")}
                        type="text"
                        fullWidth
                        variant="standard"
                        error={!!errors.username}
                        helperText={errors.username && errors.username as string}
                        value={values.username || ''}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        autoFocus
                        margin="normal"
                        id="name"
                        label={t("name")}
                        type="text"
                        fullWidth
                        variant="standard"
                        error={!!errors.name}
                        helperText={errors.name && errors.name as string}
                        value={values.name || ''}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        margin="normal"
                        id="email"
                        label={t("email")}
                        type="email"
                        fullWidth
                        variant="standard"
                        error={!!errors.email}
                        helperText={errors.email && errors.email as string}
                        value={values.email || ''}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        margin="normal"
                        id="apartment"
                        label={t("apartment")}
                        type="number"
                        fullWidth
                        variant="standard"
                        error={!!errors.apartment}
                        helperText={errors.apartment && errors.apartment as string}
                        value={values.apartment || ''}
                        onChange={formik.handleChange}
                    />
                    <Can do="update" on='UserRole'>
                        <Autocomplete
                            disablePortal={false}
                            id="roles"
                            multiple
                            options={userStore.roles}
                            getOptionLabel={(role: Role) => role.name}
                            isOptionEqualToValue={(role, value) => role.name === value.name}
                            value={formik.values.roles || []}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    error={!!errors.roles}
                                    helperText={errors.roles && errors.roles as string}
                                    margin="normal"
                                    label={t("roles")}
                                />}
                            onChange={(e, roles) => {
                                formik.setFieldValue("roles", roles)
                            }}
                        />
                    </Can>

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button color="primary" type="submit">{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});