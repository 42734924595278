import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import SideBar from '../sidebar/SideBar';
import { observer } from 'mobx-react-lite';
import Header from '../header/Header';
import { PropsWithChildren } from '../props';
import { ReactElement } from 'react';
import Notifier from './Notifier';

export interface PanelProps {
    title: string;
    headerItems?: ReactElement[];
}

const mdTheme = createTheme({
    // palette: {
    //     primary: {
    //         light: '#8bc1ab',
    //         main: '#51967a',
    //         dark: '#366351',
    //         contrastText: '#fff',
    //     },
    // },
});

export default observer((props: PropsWithChildren<PanelProps>) => {

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Header
                    title={props.title}
                    headerItems={props.headerItems}
                />
                <SideBar />
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    {props.children}
                </Box>
            </Box>
            <Notifier />

        </ThemeProvider>
    );
})