import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';
import { Counter } from '../domain/Counter';

export class CounterStore {
    rootStore: RootStore;
    serverStore: ServerStore;
    counters: { [key:string]: Counter[] }

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        this.counters = {};
        makeAutoObservable(this);
    }

    createCounter(counterData: any, callback: (result: boolean) => void) {
        this.serverStore.post('/counter', counterData, action((data) => {
            this.rootStore.notificationsStore.add('new_counter_created', "success");
            callback(true);
        }), (error) => {
            this.rootStore.notificationsStore.add('new_counter_failed', "error");
        });
    }

    updateCounter(counterData: any, callback?: (result: boolean) => void) {
        this.serverStore.patch('/counter/' + counterData.id,
        counterData,
            action((data) => {
                this.rootStore.notificationsStore.add('counter_is_updated', "success");
                callback && callback(true);
            }));
    }

    deleteCounter(counter: Counter, callback?: (result: boolean) => void) {
        this.serverStore.delete('/counter/' + counter.id,
            action((data) => {
                this.rootStore.notificationsStore.add('counter_is_deleted', "success");
                callback && callback(true);
            }));
    }

    getAllCounters(callback: (counters: Counter[]) => void) {
        this.serverStore.get(`/counter?records=true`, action((counters) => {
            callback && callback(counters);
        }));
    }

    getMyCounters(callback?: (counters: Counter[]) => void) {
        this.getCounters(this.rootStore.userStore.userData.id, callback);
    }

    getCounters(userId: number, callback?: (counters: Counter[]) => void) {
        this.serverStore.get(`/counter?userId=${userId}`, action((counters) => {
            this.counters[userId] = counters;
            callback && callback(counters);
        }));
    }
}