import { useStores } from '../../stores';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import MonthSwitcher from '../../components/MonthSwitcher';
import React from 'react';
import { useConfirm } from 'material-ui-confirm';

interface Props {
    userId: number;
    date?: Date;
    isOpen: boolean;
    onClose: () => void;
}

interface FormValues {
    [key: string]: string | Date | number;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const confirm = useConfirm();
    const { counterStore, counterRecordStore } = useStores();
    const userId = props.userId;
    const counters = counterStore.counters[userId];

    const formik = useFormik({
        // validationSchema: schema,
        initialValues: {
            date: props.date ? props.date : moment().toDate(),
            // value: props.record ? props.record.value : 0,
        } as FormValues,
        onSubmit: (values, { resetForm }) => {
            const records: any[] = [];
            const counterNumbers = Object.keys(values)
                .filter(key => key !== 'date');

            let wrong = false;
            let suspicious = false;

            for (const counterNum of counterNumbers) {
                const counter = counters.find(counter => counter.number === counterNum)!;
                if (counter) {
                    const recordDate = props.date ? props.date : moment().startOf("month").toDate();
                    const record = counter.records.find(record => moment(record.date).isSame(recordDate));
                    const prevRecord = counter.records.find(record => moment(record.date).isSame(moment(recordDate).subtract(1, 'month')));

                    const id = record && record.id;
                    const value = values[counterNum] as number;

                    if (prevRecord) {
                        if (value < prevRecord.value) {
                            wrong = true;
                        }
                        if (value - prevRecord.value >= 10) {
                            suspicious = true;
                        }
                    }

                    records.push({
                        id,
                        userId,
                        date: values.date,
                        counterId: counter.id,
                        value
                    })
                }
            }


            if (wrong) {
                alert(t("your_records_are_definitely_wrong"));
            } else if (suspicious) {
                confirm({
                    title: t("are_you_sure"),
                    description: `${t("your_records_looks_suspicious")}`,
                    confirmationText: t("yes"),
                    cancellationText: t("no")
                }).then(() => {
                    counterRecordStore.saveRecords(records, () => {
                        counterStore.getCounters(userId!);
                        resetForm();
                        props.onClose();
                    });
                });
            } else {
                counterRecordStore.saveRecords(records, () => {
                    counterStore.getCounters(userId!);
                    resetForm();
                    props.onClose();
                });
            }
        },
    });

    React.useEffect(() => {
        const date = props.date ? props.date : moment().startOf("month").toDate()
        formik.setFieldValue("date", date);
        counters.forEach((counter) => {
            const record = counter.records.find(record => moment(record.date).isSame(date));
            const prevRecord = counter.records.find(record => moment(record.date).isSame(moment(date).subtract(1, 'month')));

            if (record) {
                formik.setFieldValue(counter.number, record.value);
            }
            if (prevRecord) {
                formik.setFieldValue(`${counter.number}-prev`, prevRecord.value);
            }
        })
    }, [counters]);

    const { errors, values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>{t("new_counter_record")}</DialogTitle>
                <DialogContent>

                    <MonthSwitcher
                        date={values.date as Date}
                        disabled={!!props.date}
                        onDateUpdate={(date: Date) => {
                            formik.setFieldValue("date", date);
                        }}
                    />

                    {counters.map((counter) => {
                        const prevValue = values[`${counter.number}-prev`] || '';
                        const prevString = !!prevValue ? `(${prevValue})` : '';
                        return <TextField
                            key={counter.number}
                            margin="normal"
                            id={counter.number}
                            label={`${counter.name} - № ${counter.number} ${prevString}`}
                            type="number"
                            fullWidth
                            variant="standard"
                            color={counter.type === "cold" ? "primary" : "error"}
                            error={!!errors[counter.number]}
                            helperText={errors[counter.number] && errors[counter.number] as string}
                            value={values[counter.number] || ''}
                            onChange={formik.handleChange}
                        />
                    })}

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button color="primary" type="submit">{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});