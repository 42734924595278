import { configure } from 'mobx';
import { createContext } from 'react';
import { UserStore } from './UserStore';
import { PanelStore } from './PanelStore';
import { ServerStore } from './ServerStore';
import { ApplicationStore } from './ApplicationStore';
import { NotificationsStore } from './NotificationStore';
import { ActivityStore } from './ActivityStore';
import { AlertStore } from './AlertStore';
import { KnowledgeStore } from './KnowledgeStore';
import { CounterStore } from './CounterStore';
import { CounterRecordStore } from './CounterRecordStore';
configure({ enforceActions: "always" })

export class RootStore {
    notificationsStore: NotificationsStore;
    serverStore: ServerStore;
    applicationStore: ApplicationStore;
    userStore: UserStore;
    panelStore: PanelStore;
    counterStore: CounterStore;
    counterRecordStore: CounterRecordStore;
    activityStore: ActivityStore;
    alertStore: AlertStore;
    knowledgeStore: KnowledgeStore;

    constructor() {
        this.notificationsStore = new NotificationsStore(this);
        this.serverStore = new ServerStore(this, this.notificationsStore);
        this.applicationStore = new ApplicationStore(this, this.serverStore);
        this.userStore = new UserStore(this, this.serverStore);
        this.panelStore = new PanelStore();
        this.counterStore = new CounterStore(this, this.serverStore);
        this.counterRecordStore = new CounterRecordStore(this, this.serverStore);
        this.activityStore = new ActivityStore(this, this.serverStore);
        this.alertStore = new AlertStore(this, this.serverStore);
        this.knowledgeStore = new KnowledgeStore(this, this.serverStore);

        if (this.userStore.userData.loggedIn) {
            this.prepareData();
        }
    }

    prepareData = () => {
        this.userStore.getAllRoles();
        this.alertStore.getAlerts();
    }
}

export const rootStoreContext = createContext(new RootStore());