import { ReactElement } from "react";
import { IconButton, Toolbar } from "@mui/material";
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useStores } from "../../stores";
import { isMobileOnly } from "react-device-detect";

const drawerWidth: number = 240;

interface Props {
    title: string;
    headerItems?: ReactElement[];
}

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }: any) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default observer((props: Props) => {
    const { panelStore } = useStores();
    const toggleDrawer = () => {
        panelStore.setCollapsed(!panelStore.sideBarCollapsed);
    };

    const showHeaderItems = isMobileOnly ? panelStore.sideBarCollapsed : true;

    return (
        <AppBar style={ { backgroundColor: "#d21919c9" }} position="absolute" open={!panelStore.sideBarCollapsed}>
            <Toolbar sx={{ pr: '24px' }} > {/* keep right padding when drawer closed */}
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                        marginRight: '36px',
                        ...(!panelStore.sideBarCollapsed && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}
                >
                    {props.title}
                </Typography>
                {showHeaderItems && props.headerItems}
            </Toolbar>
        </AppBar>
    )
})
