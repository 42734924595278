import { Route, Routes } from 'react-router-dom';
import { AbilityContext } from '../../components/Can';
import { buildAbilityFor } from '../../permissions/ability';
import { useStores } from '../../stores';
import ActivityPage from '../activity/ActivityPage';
import UserDetailsPage from '../home/UserDetailsPage';
import LoginPage from '../login/LoginPage';
import AlertsPage from '../alerts/AlertsPage';
import ProtectedRoutes from '../routes/ProtectedRoutes';
import SettingsPage from '../settings/SettingsPage';
import UsersPage from '../users/UsersPage';
import OverallPage from '../overall/OverallPage';

export default () => {
    const { userStore } = useStores();
    const roles = userStore.userData && userStore.userData.roles;
    const ability = buildAbilityFor(roles);
    return (
        <div className='app-content'>
            <AbilityContext.Provider value={ability}>
                <Routes>
                    <Route path='/login' element={<LoginPage />} />
                    <Route element={<ProtectedRoutes ability={ability} />} >
                        <Route path='/' element={<UserDetailsPage />} />
                        <Route path='/home' element={<UserDetailsPage />} />
                        <Route path='/overall' element={<OverallPage />} />
                        <Route path='/users' element={<UsersPage />} />
                        <Route path='/users/:id' element={<UserDetailsPage />} />
                        <Route path='/alerts' element={<AlertsPage />} />
                        {/* <Route path='/knowledge' element={<KnowledgePage />} /> */}
                        <Route path='/activity' element={<ActivityPage />} />
                        <Route path='/settings' element={<SettingsPage />} />
                    </Route>
                </Routes >
            </AbilityContext.Provider>
        </div>
    );
}