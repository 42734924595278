import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';

export class ApplicationStore {
    rootStore: RootStore;
    serverStore: ServerStore;

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    downloadDbDump = () => {
        this.serverStore.download('/database/download')
    }
}