import * as React from 'react';
import { useState, useEffect } from 'react';

interface OpenedDialogs {
    [key: string]: boolean;
}


export function useOpenDialog() {
    const [open, setOpen] = React.useState<OpenedDialogs>({});

    const isOpen = (type: string): boolean => {
        return !!open[type];
    };

    const openDialog = React.useCallback((type: string) => {
        const obj = { ...open };
        Object.keys(obj).forEach(key => obj[key] = false);
        obj[type] = true;
        setOpen(obj);
    }, []);

    const closeDialog = React.useCallback(() => {
        const obj = { ...open };
        Object.keys(obj).forEach(key => obj[key] = false);
        setOpen(obj);
    }, []);


    return { isOpen, openDialog, closeDialog };
}