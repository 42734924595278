import { useStores } from '../../stores';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import React from 'react';
import { Counter } from '../../domain/Counter';
import MonthSwitcher from '../../components/MonthSwitcher';

interface Props {
    userId: number;
    counter?: Counter;
    isOpen: boolean;
    onClose: () => void;
}

interface FormValues {
    name: string;
    number: string;
    expiryDate: Date;
    type: string;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { counterStore } = useStores();
    const userId = props.userId;

    const formik = useFormik({
        initialValues: {
            name: props.counter ? props.counter.name : "",
            number: props.counter ? props.counter.number : "",
            expiryDate: props.counter ? props.counter.expiryDate : new Date(),
            type: props.counter ? props.counter.type : 'cold'
        } as FormValues,
        onSubmit: (values, { resetForm }) => {
            const data = {
                ...props.counter,
                name: values.name,
                number: values.number,
                expiryDate: values.expiryDate,
                type: values.type,
                userId,
            } as any;
            const callback = () => {
                counterStore.getCounters(userId);
                resetForm();
                props.onClose();
            }

            if (props.counter) {
                counterStore.updateCounter(data, callback);
            } else {
                counterStore.createCounter(data, callback);
            }

        },
    });

    React.useEffect(() => {
        formik.setFieldValue("expiryDate", props.counter ? props.counter.expiryDate : moment().toDate());
        formik.setFieldValue("name", props.counter ? props.counter.name : 0);
        formik.setFieldValue("number", props.counter ? props.counter.number : 0);
        formik.setFieldValue("type", props.counter ? props.counter.type : 'cold');
    }, [props.counter]);

    const { errors, values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>{t("new_counter")}</DialogTitle>
                <DialogContent>

                    <TextField
                        autoFocus
                        margin="normal"
                        id="name"
                        label={t("name")}
                        type="string"
                        fullWidth
                        variant="standard"
                        error={!!errors.name}
                        helperText={errors.name && errors.name as string}
                        value={values.name || ''}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        margin="normal"
                        id="number"
                        label={t("number")}
                        type="string"
                        fullWidth
                        variant="standard"
                        error={!!errors.number}
                        helperText={errors.number && errors.number as string}
                        value={values.number || ''}
                        onChange={formik.handleChange}
                    />

                    <ToggleButtonGroup
                        value={values.type || 'cold'}
                        exclusive
                        defaultValue={'cold'}
                        onChange={(e, value: string) => {
                            formik.setFieldValue("type", value);
                        }}
                    >
                        <ToggleButton color='primary' value="cold">{t("cold")}</ToggleButton>
                        <ToggleButton color='error' value="hot">{t("hot")}</ToggleButton>
                    </ToggleButtonGroup>

                    <MonthSwitcher
                        label={t("expiry_date")}
                        date={values.expiryDate as Date}
                        onDateUpdate={(date: Date) => {
                            formik.setFieldValue("expiryDate", date);
                        }}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button color="primary" type="submit">{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});