import Container from '@mui/material/Container';
import { useStores } from '../../stores';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";

interface Props {
    index: number;
    tab: number;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { applicationStore } = useStores();

    return (
        <div role="tabpanel" hidden={props.tab !== props.index} >
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Button
                    variant="contained"
                    size="large"
                    color="success"
                    onClick={() => applicationStore.downloadDbDump()}>
                    {t("backup")}
                </Button>
            </Container>
        </div>
    );
});