import Container from '@mui/material/Container';
import { useStores } from '../../stores';
import { Button, ButtonGroup, Paper, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useOpenDialog } from '../../hooks/useOpenDialog';
import UpdateUserDialog from '../users/UpdateUserDialog';
import ChangePasswordDialog from '../users/ChangePasswordDialog';

interface Props {
    index: number;
    tab: number;
}

export default observer((props: Props) => {
    const { t, i18n } = useTranslation();
    const { userStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();

    const handleChange = (event: SelectChangeEvent) => {
        i18n.changeLanguage(event.target.value);
    };

    return (
        <div role="tabpanel" hidden={props.tab !== props.index} >
            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
                <Paper
                    sx={{ p: 2, display: 'flex', flexDirection: 'column', ustifyContent: "center", alignItems: "center" }}
                >
                    <ButtonGroup
                        orientation="vertical"
                        aria-label="vertical contained button group"
                        variant="contained"
                    >
                        {/* <Button color="info" key="update-user" onClick={() => openDialog("user-info")}>
                            {t("update_user_info")}
                        </Button> */}
                        <Button color="info" key="change-password" onClick={() => openDialog("change-password")}>
                            {t("change_password")}
                        </Button>
                    </ButtonGroup>
                </Paper>
                <br></br>
                <Paper
                    sx={{ p: 2, display: 'flex', flexDirection: 'column', ustifyContent: "center", alignItems: "center" }}
                >
                    <FormControl size="medium">
                        <InputLabel>{t("language")}</InputLabel>
                        <Select
                            value={i18n.language}
                            label={t("language")}
                            onChange={handleChange}
                        >
                            <MenuItem value={"lv"}>{t("latvian")}</MenuItem>
                            <MenuItem value={"ru"}>{t("russian")}</MenuItem>
                        </Select>
                    </FormControl>
                </Paper>
                <UpdateUserDialog
                    user={userStore.userData}
                    isOpen={isOpen("user-info")}
                    onClose={closeDialog} />
                <ChangePasswordDialog
                    isOpen={isOpen("change-password")}
                    onClose={closeDialog}
                />
            </Container>
        </div>
    );
});