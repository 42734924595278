import { action, makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { ServerStore } from './ServerStore';
import { ActivityRecord } from '../domain/ActivityRecord';

export class ActivityStore {
    rootStore: RootStore;
    serverStore: ServerStore;

    constructor(rootStore: RootStore, serverStore: ServerStore) {
        this.rootStore = rootStore;
        this.serverStore = serverStore;
        makeAutoObservable(this);
    }

    getAll = (callback: (records: ActivityRecord[]) => void) => {
        this.serverStore.get('/activity', action((records: ActivityRecord[]) => { 
            callback(records);
        }));
    }


}