import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { useStores } from '../../stores';
import { AppAbility } from '../../permissions/ability';

interface Props {
    ability: AppAbility;
}

export default observer((props: Props) => {
    const location = useLocation();
    const { userStore } = useStores();
    const isAuth = userStore.userData.loggedIn;

    const contains = (paths: string[]) => {
        return paths.some(p => location.pathname.includes(p));
    }

    if (!isAuth) {
        return <Navigate to='/login' replace state={{ from: location }} />
    } else {
        let hasRole = false;
        if (contains(["home", "login", "alerts", "settings"])) {
            hasRole = true;
        } else {
            if (contains(["records", "overall"])) {
                hasRole = props.ability.can('manage', 'CounterRecord');
            } else if (contains(["users"])) {
                hasRole = props.ability.can('read', 'User');
            } else if (contains(["activity"])) {
                hasRole = props.ability.can('read', 'Activity');
            } else if (contains(["knowledge"])) {
                hasRole = props.ability.can('read', 'Knowledge');
            }
        }

        if (hasRole) {
            return <Outlet />
        }
    }
    
    return <Navigate to='/home' replace state={{ from: location }} />
});