import * as React from 'react';
import Container from '@mui/material/Container';
import Panel from '../panel/Panel';
import { useTranslation } from "react-i18next";
import { Tabs, Tab, Button } from '@mui/material';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import { observer } from 'mobx-react-lite';
import CounterRecordDialog from './CounterRecordDialog';
import CounterRecordsTable from './CounterRecordsTable';
import CountersTable from './CountersTable';
import { Counter } from '../../domain/Counter';
import CounterDialog from './CounterDialog';
import { useParams } from 'react-router-dom';
import { useStores } from '../../stores';
import Can from '../../components/Can';

export default observer(() => {
    const { t } = useTranslation();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const { userStore } = useStores();
    const [selectedCounter, setSelectedCounter] = React.useState<Counter>();
    const [tab, setTab] = React.useState(0);
    const userId = useParams().id ? Number(useParams().id) : userStore.userData.id;
    const user = userStore.users.length ? userStore.users.find(user => user.id === userId)! : userStore.userData;

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <Panel title={`${t("user")}: ${user.username}`} headerItems={[
            tab === 0 ? <Button
                key="new-counter-record-btn"
                type="button"
                color="inherit"
                onClick={() => openDialog("counter-record")}>
                {t("enter_counter_record")}
            </Button> :
                <Can key="new-counter-btn" do="manage" on='Counter'>
                    <Button
                        type="button"
                        color="inherit"
                        onClick={() => openDialog("counter")}>
                        {t("new_counter")}
                    </Button>
                </Can>
        ]}>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Tabs value={tab} onChange={handleChange}>
                    <Tab key="records" label={t("records")} />
                    <Tab key="counters" label={t("counters")} />
                </Tabs>
                <div role="tabpanel" hidden={tab !== 0} >
                    <CounterRecordsTable userId={userId} />
                </div>
                <div role="tabpanel" hidden={tab !== 1} >
                    <CountersTable
                        userId={userId}
                        onCounterUpdate={(counter: Counter) => {
                            setSelectedCounter(counter);
                            openDialog("counter");
                        }}
                    />
                </div>

            </Container>
            {isOpen("counter-record") && <CounterRecordDialog
                userId={userId}
                isOpen={isOpen("counter-record")}
                onClose={closeDialog}
            />}
            {isOpen("counter") && <CounterDialog
                userId={userId}
                counter={selectedCounter}
                isOpen={isOpen("counter")}
                onClose={() => {
                    setSelectedCounter(undefined);
                    closeDialog();
                }}
            />}
        </Panel>
    );
})