import * as React from 'react';
import { Collapse, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/system';
import { User } from '../../domain/User';
import { Counter } from '../../domain/Counter';
import moment from 'moment';
import { CounterRecord } from '../../domain/CounterRecord';

interface Props {
    user: User;
    date: Date;
    counters: Counter[];
}

export default (props: Props) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(true);

    const rowColor = () => {
        let records: CounterRecord[] = [];
        props.counters.forEach(c => records = [...records, ...c.records])
        const curRecords = records.find(r => moment(r.date).isSame(moment(props.date)));
        if (!!curRecords) {
            return "#eaffe3c9"
        } else {
            return "#ffe3e5c9";
        }
    }

    return (
        <>
            <TableRow style={{ backgroundColor: rowColor() }}>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)} >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell><strong>{`${t("username")}: ${props.user.username}`}</strong></TableCell>
                <TableCell><strong>{`${t("name")}: ${props.user.name}`}</strong></TableCell>
                <TableCell><strong>{`${t("apartment")}: ${props.user.apartment || ''}`}</strong></TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small">
                                <TableBody>
                                    {props.counters && props.counters
                                        .map((counter, i) => {
                                            const record = counter.records.find(record => moment(record.date).isSame(moment(props.date)));
                                            const value = record ? record.value : '';
                                            return <TableRow key={counter.id}>
                                                <TableCell width={"150px"}>{i + 1}</TableCell>
                                                <TableCell width={"150px"}>{counter.name}</TableCell>
                                                <TableCell width={"150px"}>{counter.number}</TableCell>
                                                <TableCell width={"150px"}>{t(counter.type)}</TableCell>
                                                <TableCell>{value}</TableCell>
                                            </TableRow>
                                        })}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};