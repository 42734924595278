import { BrowserRouter } from 'react-router-dom';
import Content from './Content';
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from 'notistack';

export default function App() {

    return (
        <BrowserRouter>
            <ConfirmProvider>
                <SnackbarProvider>
                    <Content />
                </SnackbarProvider>
            </ConfirmProvider>
        </BrowserRouter>
    );
}