import * as React from 'react';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { ActivityRecord } from '../../domain/ActivityRecord';
import moment from 'moment';

export default observer(() => {
    const { t } = useTranslation();
    const { activityStore } = useStores();
    const [records, setRecords] = React.useState<ActivityRecord[]>([]);

    React.useEffect(() => {
        activityStore.getAll((newRecords) => {
            setRecords(newRecords);
        });
    }, []);

    return (
        <Panel
            title={t("activity")}
            headerItems={[]}>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("date")}</TableCell>
                                    <TableCell>{t("user")}</TableCell>
                                    <TableCell>{t("records")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {records
                                    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                                    .map((record: ActivityRecord) => (
                                        <TableRow key={record.date+""}>
                                            <TableCell>{moment(record.date).format("DD.MM.YYYY HH:mm")}</TableCell>
                                            <TableCell><b>{record.user}</b></TableCell>
                                            <TableCell>{record.record}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Container>

        </Panel >
    );
});